import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../services/authSlice';
import { DataPermission, Study, StudyPermission } from '../services/types';
import {
  useCreateStudyPermissionMutation,
  useDeleteStudyPermissionMutation,
  useUpdateStudyMutation,
} from '../services/api';
import EjectIcon from '@mui/icons-material/Eject';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import CardActions from '@mui/material/CardActions';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import { DynamicDialog } from '../components/DynamicDialog';
import { DynamicDialogDataMap } from '../components/types';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { LoadingButton } from '@mui/lab';
import { Card, CardContent } from '@mui/material';
import { baseUrl } from '../consts';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

/**
 * The study dialogs. These are the dialogs that are displayed when the user
 * clicks the give permission or revoke permission buttons.
 * @constant
 * @type {DynamicDialogDataMap}
 */
const STUDY_DIALOGS: DynamicDialogDataMap = {
  givePermission: {
    title: 'Give Permission?',
    description: ['Are you sure you want to five permissions to collect data?'],
    cancel: 'Cancel',
    confirm: 'Give Permission',
    confirmColor: 'primary',
    confirmIcon: <AssignmentTurnedInIcon />,
  },
  revokePermission: {
    title: 'Revoke Permission?',
    description: ['This will revoke permissions to collect data'],
    cancel: 'Cancel',
    confirm: 'Revoke Permission',
    confirmColor: 'error',
    confirmIcon: <EjectIcon />,
  },
};

/**
 * The study card component. This component displays a study and
 * allows the user to give or revoke permissions to collect data.
 */
export function StudyCard({
  study,
  dataPermissions,
  studyPermissions,
  userIsConnected,
}: {
  study: Study;
  dataPermissions: DataPermission[];
  studyPermissions: StudyPermission[];
  userIsConnected: boolean;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [dialog, setDialog] = React.useState('givePermission');
  const { _id, name, description } = study;
  const [createStudyPermission, { isLoading }] =
    useCreateStudyPermissionMutation();
  const [deleteStudyPermission, { isLoading: isDeleting }] =
    useDeleteStudyPermissionMutation();
  const [updateStudy, { isLoading: isUpdating }] = useUpdateStudyMutation();

  const user = useSelector(selectCurrentUser);
  const [value, setValue] = useState('');
  const [editing, setEditing] = useState(false);

  const { id: user_id } = user || { id: '' };
  const { researchers } = study;

  const is_researcher = researchers.includes(user_id);

  const permission_given = studyPermissions.length > 0;

  // Handle the click of the give permission button.
  const handleClickGivePermission = () => {
    setDialog('givePermission');
    setOpen(true);
  };

  // Handle the click of the revoke permission button.
  const handleClickRevokePermission = () => {
    setDialog('revokePermission');
    setOpen(true);
  };

  // Handle the confirmation of the dialog.
  const handleConfirm = () => {
    setOpen(false);
    if (dialog === 'givePermission') {
      // TODO: Check if the user has already given permission to this study.
      createStudyPermission({
        study_id: _id,
        data_permission_ids: dataPermissions.map(
          (permission) => permission._id
        ),
      });
    }
    if (dialog === 'revokePermission') {
      // TODO: Check if the user has already given permission to this study.
      if (studyPermissions[0]._id) {
        deleteStudyPermission(studyPermissions[0]._id);
      }
    }
  };

  // Handle the click of the download data button.
  const handleClickDownload = () => {
    window.location.href = baseUrl + `/studies/${_id}/download_data`;
  };

  // Handle the close of the dialog.
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
      <CardHeader
        avatar={
          <Avatar color="primary" sx={{ bgcolor: 'primary.main' }}>
            {name[0]}
          </Avatar>
        }
        title={name}
        action={
          <>
            {permission_given ? (
              <Chip label={t('Permission given')} color="success" />
            ) : null}
            {is_researcher ? (
              <Chip label={t('Researcher')} color="warning" sx={{ ml: 1 }} />
            ) : null}
          </>
        }
      />
      <CardContent>
        {is_researcher && editing ? (
          <ReactQuill
            value={value}
            onChange={setValue}
            theme="snow"
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['link'],
                ['clean'],
              ],
            }}
          />
        ) : (
          <Typography
            dangerouslySetInnerHTML={{ __html: description }}
            sx={{ mb: 2 }}
          ></Typography>
        )}
        {is_researcher && !editing && (
          <LoadingButton
            color="warning"
            startIcon={<EditIcon />}
            onClick={() => {
              setEditing(true);
              setValue(description);
            }}
          >
            {t('Edit Description')}
          </LoadingButton>
        )}
        {is_researcher && editing && (
          <>
            <LoadingButton
              color="warning"
              startIcon={<CancelIcon />}
              onClick={() => {
                setEditing(false);
                setValue('');
              }}
            >
              {t('Cancel')}
            </LoadingButton>
            <LoadingButton
              color="warning"
              startIcon={<SaveAltIcon />}
              onClick={() => {
                updateStudy({
                  study_id: _id,
                  name,
                  description: value,
                });
                setEditing(false);
                setValue('');
              }}
            >
              {t('Save')}
            </LoadingButton>
          </>
        )}
        <Typography
          gutterBottom
          color="text.secondary"
          sx={{ fontSize: '.8em', mt: 2 }}
        >
          {t('This study will collect the following data:')}
        </Typography>
        <Stack direction="row" spacing={1}>
          {dataPermissions.map((permission) => (
            <Chip
              key={permission._id}
              label={permission.description}
              variant="outlined"
              color="primary"
            />
          ))}
        </Stack>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        {permission_given ? (
          <LoadingButton
            disabled={isLoading || isDeleting}
            color="error"
            startIcon={<EjectIcon />}
            onClick={handleClickRevokePermission}
          >
            {t('Revoke Permission')}
          </LoadingButton>
        ) : (
          <LoadingButton
            disabled={isLoading || isDeleting || !userIsConnected}
            color="primary"
            variant="contained"
            startIcon={<AssignmentTurnedInIcon />}
            onClick={handleClickGivePermission}
          >
            {t('Give Permission')}
          </LoadingButton>
        )}
        {is_researcher && (
          <LoadingButton
            color="warning"
            startIcon={<DownloadIcon />}
            onClick={handleClickDownload}
          >
            {t('Download Data')}
          </LoadingButton>
        )}
      </CardActions>
      <DynamicDialog
        open={open}
        handleCancel={handleClose}
        handleConfirm={handleConfirm}
        dialogData={STUDY_DIALOGS[dialog]}
      />
    </Card>
  );
}
