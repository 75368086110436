import * as React from 'react';
import { Provider } from 'react-redux';
import store from './services/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from '@mui/material/styles';
import './services/i18n';
import AppRouter from './routing/AppRouter';
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { chtTheme } from './theme';

let persistor = persistStore(store);

/**
 * The main component of the application.
 *
 * @returns JSX.Element
 */
function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={chtTheme}>
            <BrowserRouter>
              <AppRouter />
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
}

export default App;
