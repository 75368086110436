import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { User } from './types';

/**
 * Auth state type.
 */
type AuthState = {
  /**
   * User data from the API.
   */
  user: null | User;
  /**
   * Access token for the user. This is used to access protected endpoints.
   */
  accessToken: null | string;
};
/**
 * Initial state for the auth slice.
 */
const initialState: AuthState = {
  user: null,
  accessToken: null,
};

/**
 * Auth slice for storing user data and credentials. This is used by the login API
 * and the login form.
 *
 * @see src/services/login.ts
 * @see src/pages/Login.tsx
 */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { user, accessToken },
      }: PayloadAction<{ user: User; accessToken: string }>
    ) => {
      state.user = user;
      state.accessToken = accessToken;
    },
    clearCredentials: (state) => {
      state.user = null;
      state.accessToken = null;
    },
  },
  extraReducers: (builder) => {},
});

export const { setCredentials, clearCredentials } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
