import * as React from 'react';

/**
 * The "Oulun yliopisto" logo in SVG format.
 *
 * @param props  SVG props
 * @returns  JSX.Element
 */
const SVGLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props}>
    <polygon
      id="oy-logo-fi_XMLID_1089_"
      points="93.7403544 8.28273418 93.6355443 8.28273418 89.6486582 0.0205063291 84.9009873 0.0205063291 91.4584557 12.3746582 91.4584557 17.8566835 95.917443 17.8566835 95.917443 12.3746582 102.474684 0.0205063291 97.7272405 0.0205063291"
    />
    <polygon
      id="oy-logo-fi_XMLID_1088_"
      points="107.885848 0.0205063291 103.689114 0.0205063291 103.689114 17.8566835 115.230304 17.8566835 115.230304 14.0796456 107.885848 14.0796456"
    />
    <rect
      id="oy-logo-fi_XMLID_1087_"
      x="117.313975"
      y="0.0205063291"
      width="4.19673418"
      height="17.8361772"
    />
    <path
      d="M127.371418,0.0205063291 L124.118886,3.27303797 L124.118886,14.6041519 L127.371418,17.8566835 L134.505797,17.8566835 L137.758329,14.6041519 L137.758329,3.27303797 L134.505797,0.0205063291 L127.371418,0.0205063291 Z M133.561367,13.2926582 L132.77438,14.0796456 L129.075949,14.0796456 L128.315392,13.2926582 L128.315392,4.58453165 L129.10238,3.7975443 L132.77438,3.7975443 L133.561367,4.58453165 L133.561367,13.2926582 Z"
      id="oy-logo-fi_XMLID_1084_"
    />
    <path
      d="M140.366278,0.0205063291 L140.366278,17.8566835 L144.563013,17.8566835 L144.563013,12.6893165 L150.75319,12.6893165 L154.005722,9.43678481 L154.005722,3.27303797 L150.753418,0.0205063291 L140.366278,0.0205063291 Z M149.808987,8.12551899 L149.04843,8.91250633 L144.563241,8.91250633 L144.563241,3.7975443 L149.022228,3.7975443 L149.809215,4.58453165 L149.809215,8.12551899 L149.808987,8.12551899 Z"
      id="oy-logo-fi_XMLID_1081_"
    />
    <rect
      id="oy-logo-fi_XMLID_1080_"
      x="156.351646"
      y="0.0205063291"
      width="4.19673418"
      height="17.8361772"
    />
    <polygon
      id="oy-logo-fi_XMLID_1079_"
      points="167.327089 6.26308861 167.327089 4.58453165 168.113848 3.7975443 175.877772 3.7975443 175.877772 0.0205063291 166.382886 0.0205063291 163.130354 3.27303797 163.130354 7.44356962 164.992557 9.67306329 172.048329 11.6138734 172.048329 13.2926582 171.26157 14.0796456 162.763063 14.0796456 162.763063 17.8566835 172.992532 17.8566835 176.245063 14.6041519 176.245063 10.4336203 174.382861 8.20412658"
    />
    <polygon
      id="oy-logo-fi_XMLID_1078_"
      points="177.410962 3.7975443 182.394456 3.7975443 182.394456 17.8566835 186.59119 17.8566835 186.59119 3.7975443 191.574911 3.7975443 191.574911 0.0205063291 177.410962 0.0205063291"
    />
    <path
      d="M203.520759,0.0205063291 L196.38638,0.0205063291 L193.133848,3.27303797 L193.133848,14.6041519 L196.38638,17.8566835 L203.520759,17.8566835 L206.773291,14.6041519 L206.773291,3.27303797 L203.520759,0.0205063291 Z M202.576557,13.2926582 L201.78957,14.0796456 L198.091139,14.0796456 L197.330582,13.2926582 L197.330582,4.58453165 L198.11757,3.7975443 L201.78957,3.7975443 L202.576557,4.58453165 L202.576557,13.2926582 Z"
      id="oy-logo-fi_XMLID_1075_"
    />
    <polygon
      id="oy-logo-fi_XMLID_1073_"
      points="76.6114177 17.8361772 76.6114177 1.77635684e-15 72.4146835 1.77635684e-15 72.6771646 11.2525063 65.9622532 1.77635684e-15 61.3982278 1.77635684e-15 61.3982278 17.8361772 65.594962 17.8361772 65.3327089 6.58367089 72.0476203 17.8361772"
    />
    <path
      d="M10.3980759,17.8566835 L13.6549367,14.6007342 L13.6549367,3.25594937 L10.3980759,-1.77635684e-15 L3.25594937,-1.77635684e-15 L1.11910481e-13,3.25594937 L1.11910481e-13,14.6005063 L3.25594937,17.8564557 L10.3980759,17.8564557 L10.3980759,17.8566835 Z M4.20151899,4.56903797 L4.98873418,3.78182278 L8.66529114,3.78182278 L9.45250633,4.56903797 L9.45250633,13.2874177 L8.66529114,14.0746329 L4.96321519,14.0746329 L4.20151899,13.2874177 L4.20151899,4.56903797 Z"
      id="oy-logo-fi_XMLID_1070_"
    />
    <polygon
      id="oy-logo-fi_XMLID_1069_"
      points="43.6019241 14.0748608 36.2494937 14.0748608 36.2494937 -1.77635684e-15 32.0479747 -1.77635684e-15 32.0479747 17.8566835 43.6019241 17.8566835"
    />
    <polygon
      id="oy-logo-fi_XMLID_1068_"
      points="26.2246329 17.8566835 29.4814937 14.6005063 29.4814937 -1.77635684e-15 25.2790633 -1.77635684e-15 25.2790633 13.1566329 24.4918481 13.9438481 20.8162025 13.9438481 20.0289873 13.1566329 20.0289873 -1.77635684e-15 15.826557 -1.77635684e-15 15.826557 14.6005063 19.0836456 17.8566835"
    />
    <polygon
      id="oy-logo-fi_XMLID_1067_"
      points="58.7752405 14.6005063 58.7752405 -1.77635684e-15 54.5728101 -1.77635684e-15 54.5728101 13.1566329 53.7855949 13.9438481 50.1099494 13.9438481 49.3227342 13.1566329 49.3227342 -1.77635684e-15 45.1203038 -1.77635684e-15 45.1203038 14.6005063 48.3773924 17.8566835 55.5181519 17.8566835"
    />
  </svg>
);

export default SVGLogo;
