import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { selectCurrentUser } from '../services/authSlice';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';

/**
 * UserData component for displaying the current user's data on the Settings page.
 * @component
 * @returns JSX.Element
 */
export function UserData() {
  const { t } = useTranslation();
  const user = useSelector(selectCurrentUser);
  return (
    <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('User Data')}
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small" aria-label="user data">
          <TableBody>
            <TableRow>
              <TableCell variant="head">{t('First Name')}</TableCell>
              <TableCell>{user?.first_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Last Name')}</TableCell>
              <TableCell>{user?.last_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">{t('Email')}</TableCell>
              <TableCell>{user?.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
