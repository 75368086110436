import { useTranslation } from 'react-i18next';
import { UserData } from '../components/UserData';
import { UserConnections } from '../components/UserConnections';
import { Typography } from '@mui/material';
import { UserPassword } from '../components/UserPassword';

export default function Settings() {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" component="h2">
        {t('Settings')}
      </Typography>
      <UserData />
      <UserConnections />
      <UserPassword />
    </>
  );
}
