import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './api';
import auth from './authSlice';

// Combine all reducers into one root reducer
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth,
});

/**
 * Persist configuration for redux-persist.
 * This will store the redux store in local storage to persist the state between refreshes.
 */
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

/**
 * Redux store for the application.
 */
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(api.middleware);
  },
});

export default store;

// Setup listeners for the store. This is used by the login API.
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
