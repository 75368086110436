import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { SubmitHandler, useForm } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../services/authSlice';
import { CreateUserRequest, UserType } from '../services/types';
import { useCreateUserMutation } from '../services/api';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTriggerFetchMutation } from '../services/api';

export default function Admin() {
  const user = useSelector(selectCurrentUser);
  const { t } = useTranslation();
  const [createUser, createUserResponse] = useCreateUserMutation();
  const { data: createUserData, error, isLoading } = createUserResponse;
  const { register, handleSubmit } = useForm<CreateUserRequest>();
  const [triggerFetch, triggerFetchResponse] = useTriggerFetchMutation();

  if (!user || user.user_type !== UserType.ADMIN) {
    // show error if non admin user tries to access this page
    return (
      <>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography variant="h5" component="h2">
            {t('You are not authorized to access this page.')}
          </Typography>
        </Paper>
      </>
    );
  }

  console.log('triggerFetchResponse', triggerFetchResponse);

  const { start_time, end_time, duration } = triggerFetchResponse.data || {};

  console.log('error', error);
  console.log('createUserData', createUserData);

  const onSubmit: SubmitHandler<CreateUserRequest> = (data) => {
    console.log(data);
    const { email, password, first_name, last_name, user_type } = data;
    createUser({ email, password, first_name, last_name, user_type });
  };

  const onManualFetch = () => {
    triggerFetch();
  };

  // create user form
  return (
    <>
      <Typography variant="h5" component="h2">
        {t('Admin')}
      </Typography>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography variant="h5" component="h2">
          {t('Manual Fetch')}
        </Typography>
        <Typography variant="body2" component="p" sx={{ mt: 2, mb: 1 }}>
          {t('Trigger manual fetch to get new data from external services')}
        </Typography>
        {triggerFetchResponse.isLoading && (
          <>
            <CircularProgress />
            <br />
          </>
        )}
        {triggerFetchResponse.isSuccess && (
          <Alert severity="success">
            <strong>{t('Fetch successful')}</strong>
            <br />
            {t('Start time')}:
            {start_time && new Date(start_time).toLocaleString()}
            <br />
            {t('End time')}: {end_time && new Date(end_time).toLocaleString()}
            <br />
            {t('Duration')}: {duration}
          </Alert>
        )}
        {triggerFetchResponse.isError && (
          <Alert severity="error">{t('Fetch failed')}</Alert>
        )}
        <Button
          variant="contained"
          sx={{ mt: 1, mb: 2 }}
          disabled={triggerFetchResponse.isLoading}
          onClick={onManualFetch}
        >
          {t('Trigger Manual Fetch')}
        </Button>
      </Paper>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
      >
        <Typography variant="h5" component="h2">
          {t('Create User')}
        </Typography>
        <Typography variant="body2" component="p">
          {t('Create new user')}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Email Address')}
            autoComplete="email"
            autoFocus
            {...register('email')}
          />
          <TextField
            margin="normal"
            type="password"
            required
            fullWidth
            label={t('Password')}
            {...register('password')}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('First Name')}
            autoComplete="first_name"
            {...register('first_name')}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Last Name')}
            autoComplete="last_name"
            {...register('last_name')}
          />
          <FormControl fullWidth>
            <InputLabel>{t('User Type')}</InputLabel>
            <Select label={t('User Type')} {...register('user_type')}>
              <MenuItem value={1}>{t('Admin')}</MenuItem>
              <MenuItem value={2}>{t('Researcher')}</MenuItem>
              <MenuItem value={3}>{t('Participant')}</MenuItem>
            </Select>
          </FormControl>
          {error && 'originalStatus' in error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error.originalStatus === 400
                ? t('Missing field or email already exists')
                : t('Something went wrong')}
            </Alert>
          )}
          {createUserData && createUserData.success && (
            <Alert severity="success" sx={{ mt: 2 }}>
              {t('User created successfully')}
            </Alert>
          )}
          <Button
            type="submit"
            disabled={isLoading}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('Create User')}
          </Button>
        </Box>
      </Paper>
    </>
  );
}
