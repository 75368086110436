import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

/**
 * Translations for the application.
 */
const resources = {
  en: {
    translation: {
      'University of Oulu': 'University of Oulu',
      'Sign In': 'Sign In',
      'Email Address': 'Email Address',
      Password: 'Password',
      'Remember me': 'Remember me',
      Cancel: 'Cancel',
      'Forgot password?': 'Forgot password?',
      'Permission Portal': 'Permission Portal',
      'Front Page': 'Front Page',
      Permissions: 'Permissions',
      Settings: 'Settings',
      'Registered at': 'Registered at',
      'Updated at': 'Updated at',
      'User ID': 'User ID',
      'Connections to External Services': 'Connections to External Services',
      'Error while fetching connections': 'Error while fetching connections',
      'No Polar Flow connections found': 'No Polar Flow connections found',
      'User Settings': 'User Settings',
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      Email: 'Email',
      'Polar Flow': 'Polar Flow',
      'Polar Flow API': 'Polar Flow API',
      Disconnect: 'Disconnect',
      'User Data': 'User Data',
      'Disconnect Polar Flow?': 'Disconnect Polar Flow?',
      'Are you sure you want to disconnect Polar Flow?':
        'Are you sure you want to disconnect Polar Flow?',
      'You will need to reconnect to fetch new data.':
        'You will need to reconnect to fetch new data.',
      Renew: 'Renew',
      'Renew Polar Flow Connection?': 'Renew Polar Flow Connection?',
      'This will re-request authorization to the Polar Flow API':
        'This will re-request authorization to the Polar Flow API',
      'and will re-fetch all data.': 'and will re-fetch all data.',
      Studies: 'Studies',
      'No studies found': 'No studies found',
      'Give Permission': 'Give Permission',
      'Give Permission?': 'Give Permission?',
      'Are you sure you want to five permissions to collect data?':
        'Are you sure you want to five permissions to collect data?',
      'Revoke Permission': 'Revoke Permission',
      'This will revoke permissions to collect data':
        'This will revoke permissions to collect data',
      'Permission given': 'Permission given',
      'Revoke Permission?': 'Revoke Permission?',
      'Error while fetching studies.': 'Error while fetching studies.',
      'Disconnecting...': 'Disconnecting...',
      'Connect to Polar Flow': 'Connect to Polar Flow',
      'This study will collect the following data:':
        'This study will collect the following data:',
      Researcher: 'Researcher',
      'Download Data': 'Download Data',
      'Invalid email or password': 'Invalid email or password',
      'Something went wrong': 'Something went wrong',
      'You are not connected to Polar Flow':
        'You are not connected to Polar Flow',
      'Connect now': 'Connect now',
      'You are not authorized to access this page.':
        'You are not authorized to access this page.',
      'Create User': 'Create User',
      'Create new user': 'Create new user',
      'User Type': 'User Type',
      Admin: 'Admin',
      Participant: 'Participant',
      'Missing field or email already exists':
        'Missing field or email already exists',
      'User created successfully': 'User created successfully',
      'Change Password': 'Change Password',
      'Old Password': 'Old Password',
      'New Password': 'New Password',
      'Confirm New Password': 'Confirm New Password',
      'Passwords do not match': 'Passwords do not match',
      'Invalid password': 'Invalid password',
      'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character.':
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character.',
      'Password changed successfully': 'Password changed successfully',
      'Password must be at least 8 characters long.':
        'Password must be at least 8 characters long.',
      'Password must contain at least one uppercase letter.':
        'Password must contain at least one uppercase letter.',
      'Password must contain at least one lowercase letter.':
        'Password must contain at least one lowercase letter.',
      'Password must contain at least one number.':
        'Password must contain at least one number.',
      'Password must contain at least one special character.':
        'Password must contain at least one special character.',
      'Password change failed': 'Password change failed',
      'incorrect password': 'Incorrect password',
      'Edit Description': 'Edit Description',
      Save: 'Save',
      'Manual Fetch': 'Manual Fetch',
      'Trigger manual fetch to get new data from external services':
        'Trigger manual fetch to get new data from external services',
      'Fetch successful': 'Fetch successful',
      'Fetch failed': 'Fetch failed',
      'Trigger Manual Fetch': 'Trigger Manual Fetch',
      'Start time': 'Start time',
      'End time': 'End time',
      Duration: 'Duration',
    },
  },
  fi: {
    translation: {
      'University of Oulu': 'Oulun yliopisto',
      'Sign In': 'Kirjaudu sisään',
      'Email Address': 'Sähköpostiosoite',
      Password: 'Salasana',
      'Remember me': 'Muista minut',
      Cancel: 'Peruuta',
      'Forgot password?': 'Unohditko salasanasi?',
      'Permission Portal': 'Lupaportaali',
      'Front Page': 'Etusivu',
      Permissions: 'Luvat',
      Settings: 'Asetukset',
      'Registered at': 'Rekisteröity',
      'Updated at': 'Päivitetty',
      'User ID': 'Käyttäjätunnus',
      'Connections to External Services': 'Yhteydet ulkoisiin palveluihin',
      'Error while fetching connections':
        'Virhe haettaessa yhteyksiä ulkoisiin palveluihin',
      'No Polar Flow connections found': 'Polar Flow -yhteyksiä ei löytynyt',
      'User Settings': 'Käyttäjäasetukset',
      'First Name': 'Etunimi',
      'Last Name': 'Sukunimi',
      Email: 'Sähköposti',
      'Polar Flow': 'Polar Flow',
      'Polar Flow API': 'Polar Flow API',
      Disconnect: 'Katkaise yhteys',
      'User Data': 'Käyttäjätiedot',
      'Disconnect Polar Flow?': 'Katkaise Polar Flow -yhteys?',
      'Are you sure you want to disconnect Polar Flow?':
        'Haluatko varmasti katkaista Polar Flow -yhteyden?',
      'You will need to reconnect to fetch new data.':
        'Yhteys täytyy muodostaa uudelleen, jotta uusia tietoja voidaan hakea.',
      Renew: 'Uudista',
      'Renew Polar Flow Connection?': 'Uudista Polar Flow -yhteys?',
      'This will re-request authorization to the Polar Flow API':
        'Tämä pyytää uudelleen luvan Polar Flow -rajapintaan',
      'and will re-fetch all data.': 'ja hakee kaikki tiedot uudelleen.',
      Studies: 'Tutkimukset',
      'No studies found': 'Tutkimuksia ei löytynyt',
      'Give Permission': 'Anna lupa',
      'Give Permission?': 'Anna lupa?',
      'Are you sure you want to five permissions to collect data?':
        'Haluatko varmasti antaa luvan tietojen keräämiseen?',
      'Revoke Permission': 'Peru lupa',
      'This will revoke permissions to collect data':
        'Tämä peruuttaa luvan tietojen keräämiseen',
      'Permission given': 'Lupa annettu',
      'Revoke Permission?': 'Peru lupa?',
      'Error while fetching studies.': 'Virhe haettaessa tutkimuksia.',
      'Disconnecting...': 'Katkaistaan yhteyttä...',
      'Connect to Polar Flow': 'Yhdistä Polar Flow -palveluun',
      'This study will collect the following data:':
        'Tämä tutkimus kerää seuraavat tiedot:',
      Researcher: 'Tutkija',
      'Download Data': 'Lataa tutkimusdata',
      'Invalid email or password': 'Virheellinen sähköposti tai salasana',
      'Something went wrong': 'Jotain meni pieleen',
      'You are not connected to Polar Flow':
        'Et ole yhteydessä Polar Flow -palveluun',
      'Connect now': 'Yhdistä nyt',
      'You are not authorized to access this page.':
        'Sinulla ei ole oikeutta nähdä tätä sivua.',
      'Create User': 'Luo käyttäjä',
      'Create new user': 'Luo uusi käyttäjä',
      'User Type': 'Käyttäjätyyppi',
      Admin: 'Ylläpitäjä',
      Participant: 'Osallistuja',
      'User created successfully': 'Käyttäjä luotu onnistuneesti',
      'Missing field or email already exists':
        'Puuttuva kenttä tai sähköpostiosoite on jo olemassa',
      'Change Password': 'Vaihda salasana',
      'Old Password': 'Vanha salasana',
      'New Password': 'Uusi salasana',
      'Confirm New Password': 'Vahvista uusi salasana',
      'Passwords do not match': 'Salasanat eivät täsmää',
      'Invalid password': 'Virheellinen salasana',
      'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number and one special character.':
        'Salasanan pitää olla vähintään 8 merkkiä pitkä ja sisältää vähintään yksi iso kirjain, yksi pieni kirjain, yksi numero ja yksi erikoismerkki.',
      'Password changed successfully': 'Salasana vaihdettu onnistuneesti',
      'Password must be at least 8 characters long.':
        'Salasanan pitää olla vähintään 8 merkkiä pitkä.',
      'Password must contain at least one uppercase letter.':
        'Salasanassa pitää olla vähintään yksi iso kirjain.',
      'Password must contain at least one lowercase letter.':
        'Salasanassa pitää olla vähintään yksi pieni kirjain.',
      'Password must contain at least one number.':
        'Salasanassa pitää olla vähintään yksi numero.',
      'Password must contain at least one special character.':
        'Salasanassa pitää olla vähintään yksi erikoismerkki.',
      'Password change failed': 'Salasanan vaihto epäonnistui',
      'incorrect password': 'Virheellinen salasana',
      'Edit Description': 'Muokkaa kuvausta',
      Save: 'Tallenna',
      'Manual Fetch': 'Manuaalinen haku',
      'Trigger manual fetch to get new data from external services':
        'Käynnistä manuaalinen haku uusien tietojen hakemiseksi ulkoisista palveluista',
      'Fetch successful': 'Haku onnistui',
      'Fetch failed': 'Haku epäonnistui',
      'Trigger Manual Fetch': 'Käynnistä manuaalinen haku',
      'Start time': 'Aloitusaika',
      'End time': 'Lopetusaika',
      Duration: 'Kesto',
    },
  },
};

/**
 * i18next instance. Used for translations.
 */
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      convertDetectedLanguage: (lng: string) => {
        console.log('convertDetectedLanguage', lng);
        return lng;
      },
    },

    resources,
    fallbackLng: 'en',
    // debug only when not in production
    debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
