import { createTheme } from '@mui/material/styles';

/* Custom theme for this app */
export const chtTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#23408f',
    },
    secondary: {
      main: '#e30087',
      contrastText: '#fff',
    },
    info: {
      main: '#00aeef',
      contrastText: '#fff',
    },
  },
});
