import * as React from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FrontPage from '../pages/FrontPage';
import Settings from '../pages/Settings';
import Admin from '../pages/Admin';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../services/authSlice';
import { UserType } from '../services/types';
/**
 * Defines a route of the application.
 *
 * @property {string} path - The path of the route.
 * @property {React.ReactNode} icon - The icon to display in the drawer.
 * @property {string} label - The label to display in the drawer.
 * @property {React.ComponentType<any>} Component - The component to render.
 * @property {boolean} [exact] - Whether the route is exact.
 */
type AppRoute = {
  path: string;
  icon: React.ReactNode;
  label: string;
  Component: React.ComponentType<any>;
  exact?: boolean;
};

/**
 * Custom hook to get the routes of the application.
 * @category Hooks
 * @see {@link AppRouter}
 * @see {@link DrawerMenu}
 * @returns {AppRoute[]} The routes of the application.
 */
export const useDashboardRoutes = (): AppRoute[] => {
  const user = useSelector(selectCurrentUser);

  // console.log('user', user);

  const routes: AppRoute[] = [
    {
      path: '/',
      icon: <ViewAgendaIcon />,
      label: 'Studies',
      Component: FrontPage,
      exact: true,
    },
    {
      path: '/settings',
      icon: <ManageAccountsIcon />,
      label: 'Settings',
      Component: Settings,
    },
  ];

  if (user?.user_type === UserType.ADMIN) {
    routes.push({
      path: '/admin',
      icon: <SupervisorAccountIcon />,
      label: 'Admin',
      Component: Admin,
    });
  }
  return routes;
};
