import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from '../consts';
import {
  LoginQuery,
  LoginResponse,
  Study,
  DataPermission,
  StudyPermission,
  Connections,
  PolarFlowAuthorizationEndpointResponse,
  PolarFlowDeleteEndpointResponse,
  CreateUserResponse,
  CreateUserRequest,
  UpdateStudyRequest,
  UpdateStudyResponse,
  FetchResponse,
  CsrfTokenResponse,
} from './types';

/**
 * Login API for logging in the application.
 *
 * @see src/pages/Login.tsx
 * @see src/services/types.ts
 * @see src/services/store.ts
 * @see src/services/authSlice.ts
 * @see src/consts.ts
 */
export const api = createApi({
  reducerPath: 'restApi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('csrf-token');
      if (token) {
        headers.set('x-csrf-token', token);
      }
      return headers;
    },
  }),
  tagTypes: [
    'Login',
    'DataPermission',
    'Study',
    'StudyPermission',
    'Connections',
    'User',
    'Fetch',
    'CsrfToken',
  ],
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginQuery>({
      transformResponse: (response: LoginResponse) => {
        return response;
      },
      query: (body) => ({
        url: `auth/login`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        'DataPermission',
        'Study',
        'StudyPermission',
        'Connections',
      ],
    }),
    protected: builder.mutation({
      query: () => 'protected',
      invalidatesTags: [
        'DataPermission',
        'Study',
        'StudyPermission',
        'Connections',
      ],
    }),
    getDataPermissions: builder.query<DataPermission[], void>({
      query: () => 'data_permissions',
      providesTags: ['DataPermission'],
    }),
    createDataPermission: builder.mutation({
      query: (body) => ({
        url: `data_permissions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['DataPermission'],
    }),
    deleteDataPermission: builder.mutation({
      query: (id) => ({
        url: `data_permissions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DataPermission'],
    }),
    getStudies: builder.query<Study[], void>({
      query: () => 'studies',
      providesTags: ['Study'],
    }),
    createStudy: builder.mutation({
      query: (body) => ({
        url: `studies`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Study'],
    }),
    updateStudy: builder.mutation<UpdateStudyResponse, UpdateStudyRequest>({
      query: ({ study_id, name, description }) => ({
        url: `studies/${study_id}`,
        method: 'PUT',
        body: { name, description },
      }),
      invalidatesTags: ['Study'],
    }),
    deleteStudy: builder.mutation({
      query: (id) => ({
        url: `studies/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Study'],
    }),
    getStudyPermissions: builder.query<StudyPermission[], void>({
      query: () => 'study_permissions',
      providesTags: ['StudyPermission'],
    }),
    createStudyPermission: builder.mutation({
      query: (body) => ({
        url: `study_permissions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['StudyPermission'],
    }),
    deleteStudyPermission: builder.mutation({
      query: (id: string) => ({
        url: `study_permissions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['StudyPermission'],
    }),
    getUserConnections: builder.query<Connections, void>({
      query: () => 'user/connections',
      providesTags: ['Connections'],
    }),
    getCsrfToken: builder.query<CsrfTokenResponse, void>({
      transformResponse: (response: CsrfTokenResponse) => {
        /* store token in local storage */
        localStorage.setItem('csrf-token', response.csrf_token);
        return response;
      },
      query: () => ({ url: 'csrf-token', method: 'GET' }),
      keepUnusedDataFor: 1,
      providesTags: ['CsrfToken'],
    }),
    createUserConnection: builder.mutation({
      query: (body) => ({
        url: `user/connections`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Connections'],
    }),
    deleteUserConnection: builder.mutation({
      query: (id) => ({
        url: `user/connections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Connections'],
    }),
    getAuthorizationEndpoint: builder.mutation<
      PolarFlowAuthorizationEndpointResponse,
      void
    >({
      transformResponse: (response: PolarFlowAuthorizationEndpointResponse) => {
        return response;
      },
      query: () => ({
        url: '/polar_flow/get_authorization_endpoint',
        method: 'GET',
      }),
      invalidatesTags: ['Connections'],
    }),
    deleteAuthorization: builder.mutation<
      PolarFlowDeleteEndpointResponse,
      void
    >({
      query: () => ({
        url: '/polar_flow/delete_authorization',
        method: 'DELETE',
      }),
      invalidatesTags: ['Connections'],
    }),
    createUser: builder.mutation<CreateUserResponse, CreateUserRequest>({
      query: (body) => ({
        url: `/create_user`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: `/user/change_password`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    triggerFetch: builder.mutation<FetchResponse, void>({
      transformResponse: (response: FetchResponse) => {
        return response;
      },
      query: () => ({
        url: `/admin/manual_fetch`,
        method: 'GET',
      }),
      invalidatesTags: ['Fetch'],
    }),
  }),
});

export const {
  useLoginMutation,
  useProtectedMutation,
  useGetDataPermissionsQuery,
  useCreateDataPermissionMutation,
  useDeleteDataPermissionMutation,
  useGetStudiesQuery,
  useCreateStudyMutation,
  useDeleteStudyMutation,
  useGetStudyPermissionsQuery,
  useCreateStudyPermissionMutation,
  useDeleteStudyPermissionMutation,
  useGetUserConnectionsQuery,
  useCreateUserConnectionMutation,
  useDeleteUserConnectionMutation,
  useGetAuthorizationEndpointMutation,
  useDeleteAuthorizationMutation,
  useCreateUserMutation,
  useChangePasswordMutation,
  useUpdateStudyMutation,
  useTriggerFetchMutation,
  useGetCsrfTokenQuery,
} = api;
