import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../services/authSlice';
import { Navigate, useLocation } from 'react-router-dom';

/**
 * A wrapper component that redirects to the login page if the user is not logged in.
 *
 * @param  JSX.Element children
 * @returns JSX.Element
 */
export default function RequireAuth({ children }: { children: JSX.Element }) {
  const user = useSelector(selectCurrentUser);
  let location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
