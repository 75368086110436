import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch } from 'react-redux';
import UserName from '../components/UserName';
import Copyright from '../components/Copyright';
import DrawerMenu from '../components/DrawerMenu';
import AppBar from '../components/AppBar';
import SVGLogo from '../components/SVGLogo';
import { Outlet } from 'react-router-dom';
import { logout } from '../services/logout';

/**
 * The main dashboard page. This is the page that is displayed after a user logs in.
 *
 * @returns  JSX.Element
 */
export default function Dashboard() {
  const dispatch = useDispatch();

  // The drawer menu state (open/close)
  const [open, setOpen] = React.useState(true);

  // Handle the drawer menu toggle
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // Handle the logout
  const handleLogout = () => {
    logout(dispatch);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <SVGLogo
            style={{
              height: '1.125rem',
              width: '13rem',
              marginRight: '1.125rem',
              flexGrow: 1,
            }}
            fill="#fff"
          />
          <UserName />
          <IconButton color="inherit" onClick={handleLogout}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerMenu open={open} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Outlet />
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}
