import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { PolarFlowConnections } from './PolarFlowConnections';
import Typography from '@mui/material/Typography';
import CableIcon from '@mui/icons-material/Cable';
import { Button } from '@mui/material';
import {
  useGetAuthorizationEndpointMutation,
  useGetUserConnectionsQuery,
} from '../services/api';
import { useEffect } from 'react';

/**
 * UserConnections component for displaying the user connections on the Settings page.
 * @component
 * @returns JSX.Element
 */
export function UserConnections() {
  const { t } = useTranslation();
  const { data: connections, isLoading } = useGetUserConnectionsQuery(
    undefined,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );
  const [
    getAuthorizationEndpoint,
    { data: polarApiAuthData, isLoading: polarApiAuthIsLoading },
  ] = useGetAuthorizationEndpointMutation();

  useEffect(() => {
    // redirect to Polar Flow authorization endpoint
    if (polarApiAuthData?.endpoint_url) {
      window.location.href = polarApiAuthData.endpoint_url;
    }
  }, [polarApiAuthData?.endpoint_url]);

  // handle the connect button
  const handleClickConnect = () => {
    getAuthorizationEndpoint();
  };

  if (isLoading || polarApiAuthIsLoading) {
    return <CircularProgress />;
  }

  return (
    <Paper variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('Connections to External Services')}
      </Typography>
      {!connections ? (
        <Alert severity="error">{t('Error while fetching connections')}</Alert>
      ) : connections.polar_flow.length === 0 ? (
        <>
          <Alert severity="info">{t('No Polar Flow connections found')}</Alert>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CableIcon />}
            onClick={handleClickConnect}
            sx={{ mt: 2 }}
          >
            {t('Connect to Polar Flow')}
          </Button>
        </>
      ) : (
        <PolarFlowConnections connections={connections} />
      )}
    </Paper>
  );
}
