import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Connections, PolarFlowConnection } from '../services/types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import EjectIcon from '@mui/icons-material/Eject';
import RefreshIcon from '@mui/icons-material/Refresh';
import { DynamicDialog } from './DynamicDialog';
import { DynamicDialogDataMap } from './types';
import {
  useGetAuthorizationEndpointMutation,
  useDeleteAuthorizationMutation,
} from '../services/api';
import { CircularProgress } from '@mui/material';

/**
 * The study dialogs. These are the dialogs that are displayed when the user
 * clicks the renew or disconnect buttons.
 * @constant
 * @type {DynamicDialogDataMap}
 */
const POLAR_FLOW_DIALOGS: DynamicDialogDataMap = {
  disconnect: {
    title: 'Disconnect Polar Flow?',
    description: [
      'Are you sure you want to disconnect Polar Flow?',
      'You will need to reconnect to fetch new data.',
    ],
    cancel: 'Cancel',
    confirm: 'Disconnect',
    confirmColor: 'error',
    confirmIcon: <EjectIcon />,
  },
  renew: {
    title: 'Renew Polar Flow Connection?',
    description: [
      'This will re-request authorization to the Polar Flow API',
      'and will re-fetch all data.',
    ],
    cancel: 'Cancel',
    confirm: 'Renew',
    confirmColor: 'info',
    confirmIcon: <RefreshIcon />,
  },
};

/**
 * Props for the PolarFlowConnections component.
 * @interface
 * @property {Connections} connections - The connections data from the API.
 */
type PolarFlowConnectionsProps = {
  connections: Connections;
};

/**
 * PolarFlowConnections component for displaying the Polar Flow connections.
 * @component
 * @param {PolarFlowConnectionsProps} props - The props of the component.
 * @returns {JSX.Element} - The component's elements.
 */
export function PolarFlowConnections({
  connections,
}: PolarFlowConnectionsProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState('disconnect');
  const [getAuthorizationEndpoint] = useGetAuthorizationEndpointMutation();
  const [deleteAuthorization, { isLoading: deleteIsLoading }] =
    useDeleteAuthorizationMutation();

  // open the dialog and set the dialog type to disconnect
  const handleClickDisconnectOpen = () => {
    setDialog('disconnect');
    setOpen(true);
  };

  // open the dialog and set the dialog type to renew
  const handleClickRenewOpen = () => {
    setDialog('renew');
    setOpen(true);
  };

  // handle the confirm button
  const handleConfirm = () => {
    setOpen(false);
    if (dialog === 'disconnect') {
      deleteAuthorization();
    } else if (dialog === 'renew') {
      getAuthorizationEndpoint();
    }
  };

  // handle the close button
  const handleClose = () => {
    setOpen(false);
  };

  // show loading indicator while deleting
  if (deleteIsLoading) {
    return (
      <>
        <CircularProgress />
        <Typography>{t('Disconnecting...')}</Typography>
      </>
    );
  }

  const { polar_flow } = connections;

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="h6">{t('Polar Flow API')}</Typography>
      <TableContainer component={Paper} variant="outlined" sx={{ mb: 2 }}>
        <Table size="small" aria-label="polar flow connections">
          <TableBody>
            {polar_flow.map((connection: PolarFlowConnection) => (
              <React.Fragment key={connection.id}>
                <TableRow>
                  <TableCell variant="head">{t('Registered at')}</TableCell>
                  <TableCell>{connection.created_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t('Updated at')}</TableCell>
                  <TableCell>{connection.updated_at}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">{t('User ID')}</TableCell>
                  <TableCell>{connection.x_user_id}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="error"
        startIcon={<EjectIcon />}
        onClick={handleClickDisconnectOpen}
      >
        {t('Disconnect')}
      </Button>
      <Button
        variant="contained"
        color="info"
        startIcon={<RefreshIcon />}
        sx={{ ml: 1 }}
        onClick={handleClickRenewOpen}
      >
        {t('Renew')}
      </Button>
      <DynamicDialog
        open={open}
        handleCancel={handleClose}
        handleConfirm={handleConfirm}
        dialogData={POLAR_FLOW_DIALOGS[dialog]}
      />
    </Box>
  );
}
