import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { StudyCard } from '../components/StudyCard';
import {
  useGetStudiesQuery,
  useGetStudyPermissionsQuery,
  useGetDataPermissionsQuery,
  useGetUserConnectionsQuery,
} from '../services/api';
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Link,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from '../services/logout';

/**
 *
 * @returns
 */
export default function Home() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // studies
  const {
    data: studies,
    error,
    isLoading,
  } = useGetStudiesQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  // data permissions
  const {
    data: permissions,
    error: permissionsError,
    isLoading: permissionsIsLoading,
  } = useGetDataPermissionsQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  // study permissions
  const {
    data: studyPermissions,
    error: studyPermissionsError,
    isLoading: studyPermissionsIsLoading,
  } = useGetStudyPermissionsQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  // user connections
  const { data: connections, isLoading: connectionsLoading } =
    useGetUserConnectionsQuery(undefined, {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    });

  // show a loading indicator if the data is loading
  if (
    isLoading ||
    permissionsIsLoading ||
    studyPermissionsIsLoading ||
    connectionsLoading
  ) {
    return <CircularProgress />;
  }

  const userIsConnected = connections?.polar_flow.length !== 0;

  // if there is an error, check if it is a 401 error and logout
  // TODO: this should be handled in a better way
  if (error || permissionsError || studyPermissionsError) {
    if (
      (error && 'status' in error && error.status === 401) ||
      (permissionsError &&
        'status' in permissionsError &&
        permissionsError.status === 401) ||
      (studyPermissionsError &&
        'status' in studyPermissionsError &&
        studyPermissionsError.status === 401)
    ) {
      logout(dispatch);
    }
  }

  // show a loading indicator if the data is loading
  if (!studies || !permissions || !studyPermissions) {
    return (
      <>
        <Container>
          <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
          >
            <Typography variant="h5" component="h2">
              {t('Studies')}
            </Typography>
            <Typography variant="body1" sx={{ my: 2 }}>
              {t('Loading studies...')}
            </Typography>
          </Paper>
        </Container>
      </>
    );
  }
  return (
    <>
      <Typography variant="h5" component="h2">
        {t('Studies')}
      </Typography>
      {!userIsConnected ? (
        <Card variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
          <Alert severity="warning">
            {t('You are not connected to Polar Flow')}
            <br />
            <Link href="/settings" variant="body2">
              {t('Connect now')}
            </Link>
          </Alert>
        </Card>
      ) : null}
      {studies.length === 0 ? (
        <Card variant="outlined" sx={{ my: { xs: 3 }, p: { xs: 2, md: 3 } }}>
          <Alert severity="info">{t('No studies found')}</Alert>
        </Card>
      ) : (
        studies.map((study) => (
          <StudyCard
            key={study._id}
            study={study}
            userIsConnected={userIsConnected}
            dataPermissions={
              permissions.filter((permission) =>
                study.data_permissions.includes(permission._id)
              ) || []
            }
            studyPermissions={
              studyPermissions?.filter(
                (permission) => permission.study_id === study._id
              ) || []
            }
          />
        ))
      )}
    </>
  );
}
