import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoginPayload } from '../services/types';
import { useLoginMutation, useGetCsrfTokenQuery } from '../services/api';
import { setCredentials } from '../services/authSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Copyright from '../components/Copyright';
import SVGLogo from '../components/SVGLogo';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';

/**
 * The login page. This is the page that is displayed when a user is not logged in.
 *
 * @returns JSX.Element
 */
export default function Login() {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm<LoginPayload>();

  const [login, loginResponse] = useLoginMutation();
  const { data: loginData, isLoading, error } = loginResponse;
  const { success, user, access_token } = loginData || {};
  const { data: csrfTokenData } = useGetCsrfTokenQuery();

  // If the user is already logged in, redirect to the dashboard
  useEffect(() => {
    if (success && user && access_token) {
      dispatch(setCredentials({ user, accessToken: access_token }));
      navigate('/');
    }
  }, [success, user, access_token, dispatch, navigate]);

  console.log('error', error);
  // Handle the form submission
  const onSubmit: SubmitHandler<LoginPayload> = (data) => {
    console.log(data);
    const { email, password } = data;
    login({ email, password });
  };

  console.log('csrfTokenData', csrfTokenData);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SVGLogo
          style={{
            height: '1.125rem',
            width: '13rem',
            marginBottom: '1.125rem',
          }}
          fill={palette.primary.main}
        />
        <Typography component="h1" variant="h5">
          {t('Permission Portal')}
        </Typography>
        <Avatar sx={{ mt: 3, mb: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Sign In')}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Email Address')}
            autoComplete="email"
            autoFocus
            {...register('email')}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Password')}
            type="password"
            autoComplete="current-password"
            {...register('password')}
          />
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                {...register('remember')}
              />
            }
            label={t('Remember me')}
          />
          {error && 'status' in error && (
            <Alert severity="error">
              {error.status === 401
                ? t('Invalid email or password')
                : t('Something went wrong')}
            </Alert>
          )}
          <Button
            type="submit"
            disabled={isLoading}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t('Sign In')}
          </Button>
          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                {t('Forgot password?')}
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
