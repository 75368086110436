/**
 * Login query for the login API. This is used to login the user.
 *
 * @see src/pages/Login.tsx
 * @see src/services/login.ts
 */
export type LoginQuery = {
  /**
   * Email address of the user.
   */
  email: string;
  /**
   * Password of the user.
   */
  password: string;
};

export type ChangePasswordQuery = {
  /**
   * Old password of the user.
   */
  old_password: string;
  /**
   * New password of the user.
   */
  new_password: string;
};

export type ChangePasswordResponse = {
  /**
   * Whether the password change was successful or not.
   */
  success: boolean;
  /**
   * Message from the password change API.
   */
  message: string;
};

export type CreateUserRequest = {
  /**
   * Email address of the user.
   */
  email: string;
  /**
   * Password of the user.
   */
  password: string;
  /**
   * First name of the user.
   */
  first_name: string;
  /**
   * Last name of the user.
   */
  last_name: string;
  /**
   * Type of the user.
   */
  user_type: UserType;
};

export type CreateUserResponse = {
  /**
   * Whether the login was successful or not.
   */
  success: boolean;
  /**
   * Message from the login API.
   */
  message: string;
};

export type UpdateStudyRequest = {
  study_id: string;
  name: string;
  description: string;
};

export type UpdateStudyResponse = {
  success: boolean;
  message: string;
};

export type UpdateStudyPayload = {
  study_id: string;
  update: UpdateStudyRequest;
};

/**
 * Login payload for the login API. This is used by the login form.
 *
 * @see src/pages/Login.tsx
 * @see src/services/login.ts
 */
export type LoginPayload = {
  remember: boolean;
  email: string;
  password: string;
};

/**
 * Change password payload for the change password API. This is used by the change password form.
 */
export type ChangePasswordPayload = {
  old_password: string;
  new_password: string;
  new_password_confirm: string;
};

export enum UserType {
  ADMIN = 1,
  RESEARCHER = 2,
  USER = 3,
}

/**
 * User data. This is returned by the login API when logging in.
 *
 * @see src/pages/Login.tsx
 * @see src/services/login.ts
 */
export type User = {
  /**
   * ID of the user.
   */
  id: string;
  /**
   * Email address of the user.
   * This is also the username of the user.
   */
  email: string;
  /**
   * Type of the user.
   */
  user_type: UserType;
  /**
   * First name of the user.
   */
  first_name: string;
  /**
   * Last name of the user.
   */
  last_name: string;
};

/**
 * Login response from the login API when logging in.
 *
 * @see src/pages/Login.tsx
 * @see src/services/login.ts
 */
export type LoginResponse = {
  /**
   * Whether the login was successful or not.
   */
  success: boolean;
  /**
   * User data from the login API if the login was successful.
   */
  user: User;
  /**
   * Access token for the user. This is used to access protected endpoints.
   */
  access_token: string;
};

/**
 * Polar Flow connection data. This is returned by the user connections API when getting all of the user's connections.
 */
export type PolarFlowConnection = {
  /**
   * ID of the connection.
   */
  id: string;
  /**
   * User ID at Polar Flow.
   */
  x_user_id: number;
  /**
   * Expiration time of the connection.
   */
  expires_in: number;
  /**
   * Creation time of the connection.
   */
  created_at: string;
  /**
   * Last update time of the connection.
   */
  updated_at: string;
};

/**
 * Connections data. This is returned by the user connections API when getting all of the user's connections.
 *
 * @see src/pages/Connections.tsx
 * @see src/services/user_connections.ts
 */
export type Connections = {
  /**
   * Polar Flow connection data.
   */
  polar_flow: PolarFlowConnection[];
};

/**
 * Polar Flow authorization endpoint response. This is returned by the Polar Flow authorization endpoint API.
 */
export type PolarFlowAuthorizationEndpointResponse = {
  /**
   * The authorization endpoint for Polar Flow.
   */
  endpoint_url: string;
};

export type PolarFlowDeleteEndpointResponse = {
  /**
   * The delete endpoint for Polar Flow.
   */
  success: boolean;
  error?: string;
};
/**
 * Study permission (permission given by a user to access their data in a study)
 */
export type StudyPermission = {
  /**
   * ID of the study permission.
   */
  _id?: string;
  /**
   * ID of the study.
   */
  study_id: string;
  /**
   * ID of the user.
   */
  user_id: string;
  /**
   * Data permissions of the study permission.
   */
  data_permissions: string[];
  /**
   * Creation time of the study permission.
   */
  created_at?: string;
  /**
   * Last update time of the study permission.
   */
  updated_at?: string;
};

/**
 * Data permission (permission to access a certain type of data)
 */
export type DataPermission = {
  /**
   * ID of the data permission.
   */
  _id: string;
  /**
   * Type of data permission.
   */
  data_type: string;
  /**
   * Description of the data permission.
   */
  description: string;
  /**
   * Creation time of the data permission.
   */
  created_at: string;
  /**
   * Last update time of the data permission.
   */
  updated_at: string;
};

/**
 * Study (collection of data from different users)
 */
export type Study = {
  /**
   * ID of the study.
   */
  _id: string;
  /**
   * Name of the study.
   */
  name: string;
  /**
   * Researchers of the study.
   */
  researchers: string[];
  /**
   * Description of the study.
   */
  description: string;
  /**
   * Data permissions of the study.
   */
  data_permissions: string[];
  /**
   * Creation time of the study.
   */
  created_at: string;
  /**
   * Last update time of the study.
   */
  updated_at: string;
};

export type FetchResponse = {
  /**
   * Whether the fetch was successful or not.
   */
  success: boolean;
  /**
   * Message from the fetch API.
   */
  message: string;
  /*
   * Data from the fetch API.
   */
  start_time: string;
  end_time: string;
  duration: string;
};

export type CsrfTokenResponse = {
  /**
   * CSRF token.
   */
  success: boolean;
  csrf_token: string;
};
