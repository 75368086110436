import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiDrawer from '@mui/material/Drawer';
import { Link } from 'react-router-dom';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { drawerWidth } from '../consts';
import { useTranslation } from 'react-i18next';
import { useDashboardRoutes } from '../hooks/useDashboardRoutes';
import Divider from '@mui/material/Divider';

/**
 * Props of the {@link DrawerMenu} component.
 * @category Props
 */
type DrawerMenuProps = {
  /**
   * Whether the drawer is open.
   * @see {@link Dashboard}
   */
  open: boolean;
  /**
   * Function to toggle the drawer.
   * @see {@link Dashboard}
   */
  toggleDrawer: () => void;
};

/**
 * Styled Drawer component. Extends MUI's {@link https://mui.com/api/drawer/ | Drawer}.
 *
 * @component Component for the drawer menu.
 * @subcategory Custom
 */
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

/**
 * Custom DrawerMenu component. Extends MUI's {@link https://mui.com/api/drawer/ | Drawer}.
 * Uses {@link useDashboardRoutes} hook.
 *
 * @param props  Props of the {@link DrawerMenu} component.
 * @returns      JSX.Element
 */
export default function DrawerMenu(props: DrawerMenuProps) {
  const { t } = useTranslation();
  const { open, toggleDrawer } = props;
  const routes = useDashboardRoutes();

  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List>
        {routes.map(({ path, icon, label }) => (
          <ListItemButton key={path} component={Link} to={path}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={t(label)} />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
}
