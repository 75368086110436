/**
 * @module AppRouter
 * @category Routing
 * @packageDocumentation
 * @see {@link https://reactrouter.com/ | React Router}
 */
import { Routes, Route } from 'react-router-dom';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import RequireAuth from './RequireAuth';
import { useDashboardRoutes } from '../hooks/useDashboardRoutes';

/**
 * AppRouter component. Contains all the routes of the application.
 * @component
 * @returns JSX.Element
 */
function AppRouter() {
  const routes = useDashboardRoutes();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      >
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.Component />}
          />
        ))}
      </Route>
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default AppRouter;
