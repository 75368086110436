import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../services/authSlice';
import { Typography } from '@mui/material';

/**
 * Displays the current user's name.
 *
 * @returns  JSX.Element
 */
export default function UserName() {
  const user = useSelector(selectCurrentUser);
  return (
    <Typography
      variant="body1"
      component="p"
      sx={{
        marginRight: '1.5rem',
      }}
    >
      {user?.first_name} {user?.last_name}
    </Typography>
  );
}
